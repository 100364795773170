.header {
  height: 40%;
  position: relative;

  h1 {
    margin: 0;
    padding-top: 1vh;
    font-family: Arial, sans-serif;
    font-weight: 600;
    font-size: 25vh;
    color: #3a966d;
    text-align: center;
  }

  .time {
    position: absolute;
    bottom: 0;
    width: 100%;

    input {
      width: 45%;
      padding: 1vh 0;
      margin: 0;
      border: none;
      text-align: right;
      font-size: 3vh;
      font-weight: 600;
      outline: none;
      color: #777;

      &:nth-child(3) {
        text-align: left;
      }
    }

    .colon {
      width: 10%;
      display: inline-block;
      font-size: 4vh;
      font-weight: 600;
      color: #3a966d;
      font-family: Arial, sans-serif;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }
}

.start {
  display: block;
  width: 100%;
  height: 60%;
  border: none;
  background-color: #3a966d;
  font-size: 5vh;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.stop {
  width: 100%;
  height: 100%;
  background: none;
  border: none;

  .counter {
    display: inline-block;
    margin: 0 auto;
  }

  .time {
    font-weight: 600;
    font-size: 8rem;
  }

  .finished {
    color: #fff;
    font-weight: 600;
    font-size: 5vh;
  }
}
